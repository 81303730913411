import React from "react";

/**
 *
 * @param id - Id for Page or section title
 * @param title - Page or section title
 * @param hasSearch - Boolean used to show search box
 * @param hasSearchNotification - Boolean used to show the indicator when search has a value
 * @param hasFilter - Boolean used to show filter button
 * @param filterSearch - JSX Element for searching for a project
 * @param views - JSX element for changing project views
 * @param sort - JSX element for sorting projects
 * @param hasAddButton - Boolean used to show Add button
 * @param addButtonHandler - Function to handler onClik addButton
 * @param handleShow -Function handler for showing/hiding filter
 * @param handleSearchShow - Function handler for showing/hiding search
 *
 */
interface ProjectHeaderProps {
  id?: string;
  title?: string;
  hasSearch?: boolean;
  hasSeachNotification?: boolean;
  hasFilterNotification?: boolean;
  hasFilter?: boolean;
  filterSearch?: JSX.Element;
  views?: JSX.Element;
  sort?: JSX.Element;
  hasAddButton?: boolean;
  hasTitle?: boolean;
  addButtonHandler?: () => any;
  handleShow?: () => any;
  handleSearchShow?: () => void;
  className?: string;
  children?: React.ReactNode;
  disableFilter?: boolean;
}

/**
 *
 * Render project header
 *
 * @param id - Id for Page or section title
 * @param title - Page or section title
 * @param hasSearch - Boolean used to show search box
 * @param hasSearchNotification - Boolean used to show the indicator when search has a value
 * @param hasFilterNotification - Boolean used to show the indicator when filter has a value
 * @param hasFilter - Boolean used to show filter button
 * @param filterSearch - JSX Element for searching for a project
 * @param views - JSX element for changing project views
 * @param sort - JSX element for sorting projects
 * @param hasAddButton - Boolean used to show Add button
 * @param addButtonHandler - Function to handler onClik addButton
 * @param handleShow -Function handler for showing/hiding filter
 * @param handleSearchShow - Function handler for showing/hiding search
 * @param className - Custom class name
 *
 */
const ProjectHeader = ({
  id,
  title,
  hasSearch,
  hasSeachNotification,
  hasFilterNotification,
  filterSearch,
  views,
  sort,
  hasFilter,
  hasAddButton,
  hasTitle,
  addButtonHandler,
  handleShow,
  handleSearchShow,
  className = "",
  disableFilter,
}: ProjectHeaderProps) => {
  return (
    <React.Fragment>
      <header
        className={`l-subheader l-subheader--home l-subheader--projectheader ${className}`}
        data-testid="subheader"
        id={id}
      >
        <div className="l-subheader__left">
          {hasFilter && (
            <div className="button-group padding-right">
              <button
                className={`icon-button icon-button--secondary icon-button--lg ${hasFilterNotification ? "has-notification" : ""
                  }`}
                aria-label="Filter"
                data-testid="subheader__filter"
                title="Filter"
                disabled={disableFilter}
                onClick={handleShow}
              >
                <i className="icon icon-filter" aria-hidden="true"></i>
              </button>
              <>{views}</>
            </div>
          )}
          {hasTitle && <h1 className="heading-xxl">{title}</h1>}
        </div>
        <div className="l-subheader__right">
          <div className="button-group">
            {hasSearch && (
              <>
                <div className="u-hide-sm-down filter-search__div__desktop">{filterSearch}</div>
                <div className="global-utilities__item u-hide-sm-up">
                  <button
                    className={`icon-button icon-button--secondary icon-button--lg ${hasSeachNotification ? "has-notification" : ""
                      } `}
                    id="subheader__search"
                    data-testid="subheader__search"
                    aria-label="Search"
                    title="Seach"
                    disabled={disableFilter}
                    onClick={handleSearchShow}
                  >
                    <i className="icon icon-search" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="global-utilities__item">
                  <>{sort}</>
                </div>
              </>
            )}
            {hasAddButton && (
              <div className="global-utilities__item">
                <button
                  className="icon-button icon-button--secondary icon-button--lg"
                  id="subheader__add"
                  data-testid="subheader__add"
                  aria-label="add"
                  title="add"
                  onClick={addButtonHandler}
                >
                  <i className="icon icon-add" aria-hidden="true"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default ProjectHeader;
