import { useCallback, useContext, useEffect, useState } from "react";
import useBSTInsightsAPI from "../../../apiclients/api/bstinsightsapi";
import useAcquireToken from "../../AuthToken/Hooks/useAcquireToken";
import { ProjectDTO } from "../../../apiclients/bstinsights";
import { BSTInsightsAppContext } from "../../../layouts/PageLayout";
import { RolesEnum } from "../../../utils/roles";

const useNLMThresholdSettings = () => {
  const { getTokenSilently } = useAcquireToken();
  const { getProjectsForProjectThresholds } = useBSTInsightsAPI();
  const { UserRoles } = useContext(BSTInsightsAppContext);
  const [projects, setProjects] = useState<ProjectDTO[]>([]);

  const hasAccountOwnerRole = UserRoles.some(
    (item) => item.roleId === RolesEnum["Account Owner"]
  );

  const hasProjectAdministrator = UserRoles.some(
    (item) => item.roleId === RolesEnum["Project Administator"]
  );

  const getProjectResults = useCallback(async () => {
    const accessToken = await getTokenSilently();
    if (accessToken) {
      const results = await getProjectsForProjectThresholds(accessToken);
      setProjects(results || []);
      return results;
    }
  }, [getProjectsForProjectThresholds, getTokenSilently]);

  useEffect(() => {
    if (hasProjectAdministrator) {
      getProjectResults();
    }
  }, [getProjectResults, hasProjectAdministrator]);

  return {
    projects,
    hasAccountOwnerRole,
    hasProjectAdministrator,
  };
};
export default useNLMThresholdSettings;
