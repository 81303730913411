import { useTranslation } from "react-i18next";
import useNLMThresholdSettings from "../Hooks/useNLMSystemOverrides";
import SkeletonBlock from "../../Skeletons/SkeletonBlock";

const NLMSystemOverrides = () => {
  const { t } = useTranslation();
  const {
    handleOnChange,
    handleOnBlur,
    handleSliderOnChange,
    handleKeyPressEvent,
    handleUpEvent,
    tickMarksRange,
    rangeValues,
    rangeLimits,
    percentLeft,
    adjustment,
    isloading,
  } = useNLMThresholdSettings();
  return (
    <div className="nlm_system_overrider-settings">
      <div className="title">
        <span>{t("nlmsettings.system_target_nlm")}</span>{" "}
        {isloading && <SkeletonBlock width={"180px"} height={"52px"} />}
        {!isloading && (
          <input
            type="text"
            data-testid="nlmthresholdtextbox"
            value={rangeValues.textboxValue}
            onKeyDown={(ev) => handleKeyPressEvent(ev)}
            onChange={(ev) => handleOnChange(ev.currentTarget.value)}
            onBlur={() => handleOnBlur()}
          />
        )}
      </div>
      {isloading && (
        <div style={{ margin: "0 32px 48px" }}>
          <SkeletonBlock width={"100%"} height={"4px"} />
        </div>
      )}
      {!isloading && (
        <div className="nlm_system_overrider-range">
          <div
            className="range__tooltip"
            style={{
              left: `calc(${percentLeft}% + ${adjustment}px)`,
            }}
          >
            {rangeValues.sliderValue}
          </div>

          <div
            className="range__tooltip-caret"
            style={{
              left: `calc(${percentLeft}% + ${adjustment}px)`,
            }}
          />
          <input
            className="range__input"
            type="range"
            data-testid="nlmthresholdslider"
            min={rangeLimits.minValue}
            max={rangeLimits.maxValue}
            step={0.01}
            value={rangeValues.sliderValue}
            list="tickmarks"
            onInput={(ev) =>
              handleSliderOnChange(ev.currentTarget.valueAsNumber)
            }
            onMouseUp={(ev) => handleUpEvent(ev.currentTarget.valueAsNumber)}
            onKeyUp={(ev) => handleUpEvent(ev.currentTarget.valueAsNumber)}
            onTouchEnd={(ev) => handleUpEvent(ev.currentTarget.valueAsNumber)}
          />

          <div className="range__ticks">
            {tickMarksRange(rangeLimits.minValue, rangeLimits.maxValue).map(
              (ticks) => {
                return (
                  <span key={ticks.value} className="item">
                    {ticks.value}
                  </span>
                );
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NLMSystemOverrides;
