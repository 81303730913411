import { useCallback, useContext } from "react";
import {
  EmbeddedRequestDTO,
  OpenAPI,
  PowerBiEmbeddedService,
  UsersService,
  View,
  Filter,
  Sort,
  ProjectPredictionService,
  ProjectDTO,
  CreateUserRequest,
  UserRolesDTO,
  ProjectService,
  MachineLearningSettingsService,
  GlobalThresholdDTO,
  ProjectThresholdDTO,
  RoleDTO,
  UserInformationDTO,
  UpdateUserRoleRequest,
  Schedule,
  ScheduleService,
  UserPreferencesService,
  UserPreferencesDTO,
  PredictionError,
  PortfolioPredictionSummary,
  ProjectPredictionKeyFactors,
} from "../bstinsights";
import { BSTInsightsAppContext } from "../../layouts/PageLayout";

const useBSTInsightsAPI = () => {
  const { powerBiEmbeddedCreateEmbeddedConfiguration } = PowerBiEmbeddedService;
  const {
    usersPost,
    usersUpdateUsersRoles,
    usersGetAllRoles,
    usersGetAll,
    usersInviteUsersWithRoles,
    usersGetBackgroundProcessStatus,
    usersLastLoggedIn,
    usersUserExists,
    usersGetTotalAssignedUsers,
    usersUpdate,
    usersGetUserRoles,
    usersGetUserInformation,
    usersAddRole,
    usersRemoveRole,
  } = UsersService;
  const {
    projectPredictionGetViews,
    projectPredictionGetSorts,
    projectPredictionGetFilters,
    projectPredictionGetPredictionErrors,
  } = ProjectPredictionService;
  const {
    projectGetProjects,
    projectGetPortfolioPredictionSummary,
    projectGetProjectPredictionKeyFactors,
  } = ProjectService;
  const {
    machineLearningSettingsGetGlobalThreshold,
    machineLearningSettingsUpdateGlobalThreshold,
    machineLearningSettingsGetProjectThresholds,
    machineLearningSettingsGetProjectsForProjectThresholds,
    machineLearningSettingsMergeProjectThreshold,
  } = MachineLearningSettingsService;
  const { scheduleGetScheduleInformation, scheduleScheduleModelRun } =
    ScheduleService;
  const {
    userPreferencesGetUserPreferences,
    userPreferencesUpsertUserPreferences,
  } = UserPreferencesService;
  const { setHasError } = useContext(BSTInsightsAppContext);
  const baseURL: any = process.env.REACT_APP_BST_INSIGHTS_URL;

  OpenAPI.BASE = baseURL;

  const getEmbedConfiguration = useCallback(
    async (accessToken: string, embeddedRequest: EmbeddedRequestDTO) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const embedConfiguration =
          await powerBiEmbeddedCreateEmbeddedConfiguration(embeddedRequest);
        return embedConfiguration;
      } catch (error) {}
    },
    [powerBiEmbeddedCreateEmbeddedConfiguration]
  );

  const getViews = useCallback(
    async (
      accessToken: string,
      azureIdentityId: string,
      hasSupportGroup: boolean
    ) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const views: View[] = await projectPredictionGetViews(
          azureIdentityId,
          hasSupportGroup
        );
        return views;
      } catch (error) {
        setHasError(true);
      }
    },
    [projectPredictionGetViews, setHasError]
  );

  const getFilters = useCallback(
    async (
      accessToken: string,
      azureIdentityId: string,
      hasSupportGroup: boolean
    ) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const filters: Filter[] = await projectPredictionGetFilters(
          azureIdentityId,
          hasSupportGroup
        );
        return filters;
      } catch (error) {
        setHasError(true);
      }
    },
    [projectPredictionGetFilters, setHasError]
  );

  const getSorts = useCallback(
    async (
      accessToken: string,
      azureIdentityId: string,
      hasSupportGroup: boolean
    ) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const sorts: Sort[] = await projectPredictionGetSorts(
          azureIdentityId,
          hasSupportGroup
        );
        return sorts;
      } catch (error) {
        setHasError(true);
      }
    },
    [projectPredictionGetSorts, setHasError]
  );

  const saveUser = useCallback(
    async (accessToken: string, userModel: CreateUserRequest) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersPost([userModel]);
        return result;
      } catch (error) {
        setHasError(true);
        return { error: true, details: error };
      }
    },
    [setHasError, usersPost]
  );

  const updateUser = useCallback(
    async (accessToken: string, id: number, userModel: CreateUserRequest) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersUpdate(id, userModel);
        return result;
      } catch (error) {
        setHasError(true);
        return "error";
      }
    },
    [setHasError, usersUpdate]
  );

  const getBackgroundProcessStatus = useCallback(
    async (accessToken: string, instanceID: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersGetBackgroundProcessStatus(instanceID);
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersGetBackgroundProcessStatus]
  );

  const inviteUsersWithRoles = useCallback(
    async (accessToken: string, model: Array<CreateUserRequest>) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersInviteUsersWithRoles(model);
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersInviteUsersWithRoles]
  );

  const lastLoggedIn = useCallback(
    async (accessToken: string, model: any) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersLastLoggedIn(model);
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersLastLoggedIn]
  );

  const userExists = useCallback(
    async (accessToken: string, email: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersUserExists(email);
        return result;
      } catch (error) {
        setHasError(true);
        return null;
      }
    },
    [setHasError, usersUserExists]
  );

  const getUsers = useCallback(
    async (accessToken: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersGetAll();
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersGetAll]
  );

  const getRoles = useCallback(
    async (accessToken: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersGetAllRoles();
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersGetAllRoles]
  );

  const saveRoles = useCallback(
    async (accessToken: string, model: UserRolesDTO[]) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersUpdateUsersRoles(model);
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersUpdateUsersRoles]
  );

  const getProjects = useCallback(
    async (request: {
      accessToken: string;
      hasPredictions?: boolean;
      hasOverrides?: boolean;
      projectSearchText?: string;
    }) => {
      try {
        OpenAPI.TOKEN = request.accessToken;
        const projects: ProjectDTO[] = await projectGetProjects(
          request.hasPredictions,
          request.hasOverrides,
          request.projectSearchText
        );
        return projects;
      } catch (error) {
        setHasError(true);
      }
    },
    [projectGetProjects, setHasError]
  );

  const getTotalRoles = useCallback(
    async (accessToken: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersGetTotalAssignedUsers();
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersGetTotalAssignedUsers]
  );

  const getGlobalThreshold = useCallback(
    async (accessToken: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: GlobalThresholdDTO =
          await machineLearningSettingsGetGlobalThreshold();
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [machineLearningSettingsGetGlobalThreshold, setHasError]
  );

  const updateGlobalThreshold = useCallback(
    async (accessToken: string, request: GlobalThresholdDTO) => {
      try {
        OpenAPI.TOKEN = accessToken;
        await machineLearningSettingsUpdateGlobalThreshold(request);
      } catch (error) {
        setHasError(true);
      }
    },
    [machineLearningSettingsUpdateGlobalThreshold, setHasError]
  );

  const getProjectThresholds = useCallback(
    async (accessToken: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: ProjectThresholdDTO[] =
          await machineLearningSettingsGetProjectThresholds();
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [machineLearningSettingsGetProjectThresholds, setHasError]
  );

  const getProjectsForProjectThresholds = useCallback(
    async (accessToken: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: ProjectDTO[] =
          await machineLearningSettingsGetProjectsForProjectThresholds();
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [machineLearningSettingsGetProjectsForProjectThresholds, setHasError]
  );

  const mergeProjectThresholds = useCallback(
    async (accessToken: string, request: ProjectThresholdDTO) => {
      try {
        OpenAPI.TOKEN = accessToken;
        await machineLearningSettingsMergeProjectThreshold(request);
      } catch (error) {
        setHasError(true);
      }
    },
    [machineLearningSettingsMergeProjectThreshold, setHasError]
  );

  const getUserRoles = useCallback(
    async (
      accessToken: string,
      azureIdentityId: string,
      unrestrictedAccess?: boolean
    ) => {
      try {
        OpenAPI.TOKEN = accessToken;
        var results: RoleDTO[] = await usersGetUserRoles(
          azureIdentityId,
          unrestrictedAccess
        );
        return results;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersGetUserRoles]
  );

  const addUserRole = useCallback(
    async (accessToken: string, model: UpdateUserRoleRequest) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersAddRole(model);
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersAddRole]
  );

  const removeUserRole = useCallback(
    async (accessToken: string, model: UpdateUserRoleRequest) => {
      try {
        OpenAPI.TOKEN = accessToken;
        const result: any = await usersRemoveRole(model);
        return result;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersRemoveRole]
  );

  const getUserInformation = useCallback(
    async (accessToken: string, azureIdentityId: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        var results: UserInformationDTO = await usersGetUserInformation(
          azureIdentityId
        );
        return results;
      } catch (error) {
        setHasError(true);
      }
    },
    [setHasError, usersGetUserInformation]
  );

  const getScheduleInformation = useCallback(
    async (accessToken: string, pipelineId: number) => {
      try {
        OpenAPI.TOKEN = accessToken;
        var results: Schedule = await scheduleGetScheduleInformation(
          pipelineId
        );
        return results;
      } catch (error) {
        setHasError(true);
      }
    },
    [scheduleGetScheduleInformation, setHasError]
  );

  const scheduleModelRun = useCallback(
    async (accessToken: string, request: Schedule) => {
      try {
        OpenAPI.TOKEN = accessToken;
        await scheduleScheduleModelRun(request);
      } catch (error) {
        setHasError(true);
      }
    },
    [scheduleScheduleModelRun, setHasError]
  );

  const getUserPreferences = useCallback(
    async (
      accessToken: string,
      azureIdentityId: string,
      componentName: string
    ) => {
      try {
        OpenAPI.TOKEN = accessToken;
        var results: UserPreferencesDTO =
          await userPreferencesGetUserPreferences(
            azureIdentityId,
            componentName
          );
        return results;
      } catch (error) {}
    },
    [userPreferencesGetUserPreferences]
  );

  const upsertUserPreferences = useCallback(
    async (accessToken: string, request: UserPreferencesDTO) => {
      try {
        OpenAPI.TOKEN = accessToken;
        await userPreferencesUpsertUserPreferences(request);
      } catch (error) {}
    },
    [userPreferencesUpsertUserPreferences]
  );

  const getPredictionErrors = useCallback(
    async (accessToken: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        var results: PredictionError =
          await projectPredictionGetPredictionErrors();
        return results;
      } catch (error) {
        setHasError(true);
      }
    },
    [projectPredictionGetPredictionErrors, setHasError]
  );

  const getPortfolioPredictionSummary = useCallback(
    async (accessToken: string) => {
      try {
        OpenAPI.TOKEN = accessToken;
        var results: PortfolioPredictionSummary =
          await projectGetPortfolioPredictionSummary();
        return results;
      } catch (error) {
        setHasError(true);
      }
    },
    [projectGetPortfolioPredictionSummary, setHasError]
  );

  const getProjectPredictionKeyFactors = useCallback(
    async (accessToken: string, projectId: number) => {
      try {
        OpenAPI.TOKEN = accessToken;
        var results: ProjectPredictionKeyFactors[] =
          await projectGetProjectPredictionKeyFactors(projectId);
        return results;
      } catch (error) {
        setHasError(true);
      }
    },
    [projectGetProjectPredictionKeyFactors, setHasError]
  );

  return {
    getEmbedConfiguration,
    getViews,
    getFilters,
    getSorts,
    getProjects,
    saveUser,
    saveRoles,
    getRoles,
    getUsers,
    inviteUsersWithRoles,
    lastLoggedIn,
    userExists,
    getTotalRoles,
    getGlobalThreshold,
    updateGlobalThreshold,
    getProjectThresholds,
    mergeProjectThresholds,
    getProjectsForProjectThresholds,
    updateUser,
    getUserRoles,
    getUserInformation,
    addUserRole,
    removeUserRole,
    getBackgroundProcessStatus,
    getScheduleInformation,
    scheduleModelRun,
    getUserPreferences,
    upsertUserPreferences,
    getPredictionErrors,
    getPortfolioPredictionSummary,
    getProjectPredictionKeyFactors,
  };
};
export default useBSTInsightsAPI;
