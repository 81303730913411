import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../../i18n/config";
import useSignoutButton from "../Hooks/useSignOutButton";
import MobileMenu from "./MobileMenu";
import { useTranslation } from "react-i18next";
import ProfileMenu from "./ProfileMenu";

const NavBar = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(!show);
  const { handleLogout } = useSignoutButton();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <header className="l-header" data-testid="header">
        <div className="l-header__left u-hide-sm-down">
          <Link to="/">
            <div className="app-home is-active">
              <span className="app-home__logo" title={t("title")}>
                <i className="icon icon-bst_solid"></i>
              </span>
              <span className="app-home__app-name" data-testid="app title">
                {t("title")}
              </span>
            </div>
          </Link>
        </div>
        <div className="l-header__left u-hide-sm-up">
          <div
            tabIndex={-1}
            className="global-utilities__button btnMenuDropDown"
            onClick={handleShow}
          >
            <div
              className="global-utilities__button__icon hamburger-menu-icon"
              aria-hidden="true"
              title="Menu"
            >
              <i className="icon icon-menu"></i>
            </div>

            <MobileMenu
              show={show}
              onCloseClick={handleShow}
              handleLogout={handleLogout}
            />
          </div>
        </div>
        <div className="l-header__right">
          <nav className="global-utilities">
            <div className="global-utilities__item u-hide-sm-down">
              <ProfileMenu handleLogout={handleLogout} />
            </div>
            <div className="global-utilities__item u-hide-sm-down nav-bar-btn">
              <button
                className="global-utilities__button global-utilities__button--highlight"
                id="global-utilities__apps"
                tabIndex={-1}
                aria-label="Apps"
                aria-haspopup="true"
                aria-expanded="false"
                data-automation-id="global-utilities__apps"
              >
                <span
                  className="global-utilities__button__icon"
                  aria-hidden="true"
                  title="Apps"
                >
                  <i className="icon icon-all-apps"></i>
                </span>
              </button>
            </div>
          </nav>
        </div>
      </header>
    </React.Fragment>
  );
};

export default NavBar;
