/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PortfolioPredictionSummary } from "../models/PortfolioPredictionSummary";
import type { ProjectDTO } from "../models/ProjectDTO";
import type { ProjectPredictionKeyFactors } from "../models/ProjectPredictionKeyFactors";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ProjectService {
  /**
   * @param hasPredictions
   * @param hasOverrides
   * @param projectSearchText
   * @returns ProjectDTO
   * @throws ApiError
   */
  public static projectGetProjects(
    hasPredictions?: boolean | null,
    hasOverrides?: boolean | null,
    projectSearchText?: string | null
  ): CancelablePromise<Array<ProjectDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Project/GetProjects",
      query: {
        hasPredictions: hasPredictions,
        hasOverrides: hasOverrides,
        projectSearchText: projectSearchText,
      },
    });
  }

  /**
   * @returns PortfolioPredictionSummary
   * @throws ApiError
   */
  public static projectGetPortfolioPredictionSummary(): CancelablePromise<PortfolioPredictionSummary> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Project/GetPortfolioPredictionSummary",
    });
  }

  /**
   * @param projectId
   * @returns ProjectPredictionKeyFactors
   * @throws ApiError
   */
  public static projectGetProjectPredictionKeyFactors(
    projectId?: number
  ): CancelablePromise<Array<ProjectPredictionKeyFactors>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Project/GetProjectPredictionKeyFactors",
      query: {
        projectId: projectId,
      },
    });
  }
}
